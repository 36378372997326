import React from "react"
import AlertSvg from "@images/alert-circle.svg"
import CloseIconSvg from "@images/close.svg"
import s from "./Alert.module.scss"

export default function Alert({ message, onDismiss }) {
  return (
    <div className={s.alert}>
      <AlertSvg className={s.alertIcon} />
      <p className={s.msg}>{message}</p>
      <CloseIconSvg className={s.closeIcon} onClick={onDismiss} />
    </div>
  )
}
