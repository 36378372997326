import React from "react"
import styled, { css } from "@xstyled/emotion"
import Box from "./Box"

const errorStyles = props =>
  props.hasError &&
  css`
    border-color: borderError;
    background-color: errorBackground;
  `

const Input = styled(Box)`
  background-color: white;
  border-width: 1px;
  border-color: grays.border;
  border-radius: 4px;
  border-style: solid;
  color: black;
  font-size: 16px;
  height: 32px;
  outline: none;

  ${errorStyles}
  /* width: 235px; */
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus {
    background-color: black !important;
  }

  &:::placeholder {
    color: grays.placeholder;
  }

  &:focus {
    border-color: borderFocus;
  }
`

Input.defaultProps = {
  as: "input",
  marginBottom: 1,
  px: 1,
  py: 2,
}
export default Input
