import React, { useState, useEffect } from "react"
import LangContext from "@contexts/lang"
import { useStaticQuery, graphql } from "gatsby"
import SignupForm from "@components/common/signUpForm"
import useSearchParams from "@hooks/useSearchParams"
import { DEFAULT_COUNTRY_CODE, BE_URL } from "@utils/constants"
import axios from "axios"
import LoadingIcon from "@components/common/loading"
import { UserContact, UserDealInfo } from "@utils/types"
import SEO from "@components/common/seo/seo"
import { lang as language } from "@utils/types"
import { navigate } from "gatsby"
import _ from "lodash"

export default function RegistrationPage({ location }) {
  const { countryCode: country, setLang } = React.useContext(LangContext)
  const { deal_id } = useSearchParams(location)
  const [selectedCountry, setSelectedCountry] = useState(DEFAULT_COUNTRY_CODE)
  const [planId, setPlanId] = useState(useSearchParams(location).planId)
  const [userDealInfo, setUserDealInfo] = useState<UserDealInfo>({})
  const [userContact, setUserContact] = useState<UserContact>({})
  const [countryPlan, setCountryPlan] = useState("")
  let plan
  const plansQuery = useStaticQuery(graphql`
    query {
      plans {
        plans {
          currency {
            #   decimals
            titleAr
            titleEn
          }
          countryCode
          descriptionAr
          descriptionEn
          discountTextAr
          discountTextEn
          featuresAr
          featuresEn
          paymentMethods
          id
          interval
          paymentMethods
          position
          price
          titleAr
          titleEn
        }
      }
    }
  `)

  if (planId) {
    plan = plansQuery.plans.plans.find(plan => plan.id === planId)
  }

  const { state } = location
  const { email } = state || {}

  useEffect(() => {
    if (country !== selectedCountry) {
      setSelectedCountry(country)
    }

    if (deal_id && _.isEmpty(userDealInfo)) {
      getUserDealInfo().then(res => {
        let userAttributes = res.data.data && res.data.data.attributes
        setUserContact(userAttributes.contact)
        setUserDealInfo(userAttributes)
        setCountryPlan(userAttributes["country-plan"])
        try {
          setPlanId(userAttributes["country-plan"].id.toString())
        } catch (error) {
          alert("Please add a plan to the deal")
        }
      })
    }

    if(!_.isEmpty(userDealInfo)) {
      const dealLang = userDealInfo?.language === 'English' ? language.en : language.ar;
      setLang(dealLang);
      const [_, currentLang, ...path] = location.pathname.split("/")
      navigate(`/${dealLang}/${path.join("/")}${location.search}`)
    }
  }, [country, deal_id, userDealInfo])

  async function getUserDealInfo() {
    try {
      let response = await axios.get(`${BE_URL}/deal/${deal_id}`)
      return response
    } catch (error) {
      console.log(error)
    }
  }

  if (deal_id) {
    return userDealInfo && userContact && countryPlan ? (
      <>
        <SEO title={"Register"} location={location} />
        <SignupForm
          defaultCountry={selectedCountry}
          storeName={userDealInfo && userDealInfo?.name}
          lastName={userContact["last-name"]}
          firstName={userContact["first-name"]}
          instagram={userDealInfo && userDealInfo?.instagram}
          mobile={userContact?.mobile}
          email={userContact?.email}
          paymentUrl={userDealInfo["payment-url"]}
          planId={planId}
          plan={plan}
          dealId={deal_id}
          paymentMethod={userDealInfo['payment-method']}
          price={userDealInfo?.price}
        />
      </>
    ) : (
      <LoadingIcon />
    )
  }

  return (
    <>
      <SEO title={"Register"} location={location} />
      <SignupForm
        defaultCountry={selectedCountry}
        email={email}
        planId={planId}
        plan={plan}
      />
    </>
  )
}
