import React from "react"
import ReactPhoneInput from "react-phone-input-2"
import LangContext from "@contexts/lang"

const PhoneInput = ({ hasError, ...props }) => {
  const { lang } = React.useContext(LangContext)
  return (
    <div
      className={`phone-input-container ${hasError && "has-errors"} ${lang ===
        "ar" && "phone-flipped"}`}
    >
      <ReactPhoneInput {...props} />
    </div>
  )
}

export default PhoneInput
