import React, { useEffect, useState } from "react"
import * as Sentry from "@sentry/browser"
import LangContext from "@contexts/lang"
import { navigate } from "gatsby-plugin-intl"
import axios from "axios"
import Cookies from "js-cookie"
import { Controller, useForm } from "react-hook-form"
import { BE_URL, REGISTRATION_URL, VALIDATION_URL, ADD_HUBSPOT_CONTACT_URL} from "@utils/constants"
import Button from "@components/globals/Button"
import Flex from "@components/globals/Flex"
import Alert from "@components/globals/Alert"
import ShowPasswordIcon from "@components/globals/ShowPasswordIcon"
import ShowPaswwordWrapper from "@components/globals/ShowPaswwordWrapper"
import Box from "@components/globals/Box"
import Input from "@components/globals/Input"
import PhoneInput from "@components/globals/PhoneInput"
import Select from "@components/globals/Select"
import Eye from "@images/eye.svg"
import s from "./signUpForm.module.scss"
import formatMessage from "@utils/texts"
import FormField from "@components/globals/FormField"
import { SignUpFormFields } from "@utils/types"
import { getRefQueryParam } from "@utils/analytics"
import camelize from "@utils/camelize"
import Plan from "@components/globals/Plans/Plan"
import useTranslateField from "@hooks/useTranslateField"
import firebase from "gatsby-plugin-firebase"
import "firebase/auth"
import "firebase/firestore"
import "firebase/functions"
import trackFacebookEvent from "@utils/trackFacebookEvent"

axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*"

const COUNTRY_NAMES_BY_CODE = {
  BH: "Bahrain",
  EG: "Egypt",
  JO: "Jordan",
  KW: "Kuwait",
  OM: "Oman",
  QA: "Qatar",
  SA: "Saudi Arabia",
  AE: "United Arab Emirates",
}

function SignupForm({
  defaultCountry,
  email = "",
  lastName = "",
  firstName = "",
  instagram = "",
  mobile = "",
  storeName = "",
  paymentUrl = "",
  planId,
  plan,
  password = "password",
  dealId = "",
  paymentMethod = "",
  price = 0
}) {
  const {
    control,
    errors,
    formState: { isSubmitting },
    handleSubmit,
    setError,
    setValue,
    watch,
  } = useForm<SignUpFormFields>({
    defaultValues: { defaultCountry },
  })

  const { lang } = React.useContext(LangContext)
  const [isCommissionBased, setActiveCommission] = React.useState(false)
  const translateField = useTranslateField()

  useEffect(() => {
    setValue("country", defaultCountry)
  }, [defaultCountry, setValue])

  const [activePaymentMethod, setActivePaymentMethod] = useState<string>(null)
  const [changePlan, setChangePlan] = useState(false)
  const [showSubmissionError, setShowSubmissionError] = useState(false)
  const selectedCountry = watch("country")
  const [inputType, setInputType] = useState("password")
  const submitButtonTitle = paymentMethod === "KNET" ? "payWithKnet" : "payWithCreditCard";

  const showPassword = () => {
    setInputType(state => (state === "password" ? "input" : "password"))
  }

  if (typeof document !== "undefined") {
    var header = document.querySelector("header")
    if (header) {
      header.classList.remove("promotionSection-module--hide--29h3w")
    }
  }

  const validateData = async (data: SignUpFormFields) => {
    const {
      country,
      email,
      organizationName,
      instagram,
      firstName,
      lastName,
    } = data
    try {
      const response = await axios.post(VALIDATION_URL, {
        data: {
          attributes: {
            first_name: firstName,
            last_name: lastName,
            organization_name: organizationName,
            instagram,
            country,
            email,
            password: "password",
            mobile: data.mobile.replace(/\s/g, ""),
          },
        },
      })
      return response.data;
    } catch (error) {
      Sentry.captureException(error)
      const { response: { data: { error: errors } = {} } = {} } = error
      Object.keys(errors).map(elm => {
        let msg = elm === "mobile" ? "registerPage.invalidPhoneNumber" : "registerPage.invalidEntry";
        return setError(camelize(elm), {
          message: formatMessage({ id: msg, lang }),
        })
      })
      return false
    }
  }
  const addLeadFireBase = (data, dealId) => {
    try {
      firebase
        .database()
        .ref("/" + dealId)
        .push({
          ...data,
        })
    } catch (error) {
      console.log(error)
    }
  }

  const onSubmit = async (data: SignUpFormFields) => {
    // TESTING ONLY
    /*   navigate("/register/payment/?country=" + data.country, {
      state: {
        email: data.email,
        firstName: data.firstName,
        lastName: data.lastName,
        lead: "123123123",
        num: data.mobile.replace(/\s/g, ""),
        organizationName: data.organizationName,
      },
    }) */

    /* gaClickEvent({
      category: "Register Button",
      label: "Sign-up form",
    }) */

    const validationInfo = await validateData(data)
    if (validationInfo !== false) {
      const activeCountry = changePlan ? defaultCountry : data.country
      try {
        let hashedPasswordData = {
          ...data,
          password: validationInfo.hashed_password,
          dealId: dealId,
          paymentMethod: activePaymentMethod,
        }

        const firebaseLeadData = {
          time: new Date().toLocaleString() + "",
          ...hashedPasswordData,
          country: COUNTRY_NAMES_BY_CODE[activeCountry],
          ...(data.mobile && { mobile: data.mobile.replace(/\s/g, "") }),
        }
        addLeadFireBase(firebaseLeadData, dealId)

        const response = await axios.post(ADD_HUBSPOT_CONTACT_URL, {
          data: {
            attributes: {
              first_name: data?.firstName,
              last_name: data?.lastName,
              organization_name: data?.organizationName,
              instagram:data?.instagram,
              country:COUNTRY_NAMES_BY_CODE[activeCountry],
              email:data?.email,
              mobile: data?.mobile?.replace(/\s/g, ""),
              utm_source: getRefQueryParam("utm_source"),
              utm_medium: getRefQueryParam("utm_medium"),
              utm_campaign: getRefQueryParam("utm_campaign"),
              utm_term:getRefQueryParam("utm_term"),
              deal_id: dealId
            },
          },
        })
        
        if (response.status === 200) {
          const { email, firstName, lastName, mobile, organizationName } = data
          Cookies.remove("Source")
          if (planId && !changePlan) {
            const paymentData = {
              attributes: {
                lead_id: response?.data?.id,
                country_plan_id: planId,
                mobile: mobile.replace(/\s/g, ""),
                payment_method: activePaymentMethod,
                email,
                first_name: firstName,
                last_name: lastName,
                language: lang === "en" ? "english" : "arabic",
                organization_name: organizationName,
              },
            }
            try {
              if (paymentUrl !== "") {
                window.location.href = `${BE_URL}/${paymentUrl}`
              } else {
                const { data: { url } = {} } = await axios.post(
                  `${BE_URL}/plan_payment`,
                  {
                    data: paymentData,
                  }
                )
                if (url) {
                  window.location.assign(url)
                }
              }
            } catch (error) {
              //TODO: handle failure by showing message to the user
              Sentry.captureException(error)
              //  setActivePaymentMethod()
            }
          } else {
            navigate("/thanks")
          }
        } else {
          Sentry.withScope(scope => {
            scope.setUser({ email: email })
            Sentry.captureException(new Error("No lead id returned!"))
          })
        }
      } catch (error) {
        if(error.response.status === 422) {
          Object.keys(error.response.data.error).map(elm => {
            let msg = "registerPage.invalidEntry";
            if(elm === "mobile") msg = "registerPage.invalidPhoneNumber";
            if(elm === "email") msg = "registerPage.emailExists";
            return setError(camelize(elm), {
              message: formatMessage({ id: msg, lang }),
            })
          });
        } else {
          setShowSubmissionError(true);
        }
        Sentry.captureException(error)
      }
      const fbEvent = {
        first_name: data?.firstName,
        last_name: data?.lastName,
        country: data?.country || defaultCountry,
        phone: data?.mobile?.replace(/\s/g, ""),
        email: data?.email,
      }
      trackFacebookEvent("Lead", fbEvent)
    }
  }

  function handleErrorDismiss() {
    setShowSubmissionError(false)
  }

  let submitButtons = (
    <Button
      disabled={isSubmitting}
      isLoading={isSubmitting}
      mt={1}
      type="submit"
      id="lead-submit-button"
    >
      {formatMessage({ id: "registerPage.submit", lang })}
    </Button>
  )

  if (planId) {
    submitButtons = (
      <Flex>
        <Button
          borderRadius="4px"
          disabled={isSubmitting}
          isLoading={isSubmitting && activePaymentMethod === "CARD"}
          onClick={() => setActivePaymentMethod("CARD")}
          mr={1}
          name="payment"
          type="submit"
          py={1}
          px={2}
        >
          {formatMessage({ id: submitButtonTitle, lang })}
        </Button>
      </Flex>
    )
  }

  return (
    <div>
      <h3 className={s.signUpHeader}>
        {formatMessage({ id: "registerPage.mainTitle", lang })}
        <br />
        <p>{formatMessage({ id: "registerPage.secondaryTitle", lang })}</p>
      </h3>
      <div className={s.signUpForm}>
        <div className={s.formContainer}>
          {showSubmissionError && (
            <Alert
              message={formatMessage({ id: "registerPage.formError", lang })}
              onDismiss={handleErrorDismiss}
            />
          )}
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className={s.formFieldsContainer}>
              <div className={s.titleContainer}>
                <h5>{formatMessage({ id: "registerPage.title", lang })}</h5>
                {!planId && (
                  <FormField
                    className={s.formField}
                    error={errors.country}
                    style={{ width: "auto" }}
                  >
                    <Controller
                      as={Select}
                      control={control}
                      defaultValue={defaultCountry}
                      name="country"
                      rules={{
                        required: formatMessage({
                          id: "registerPage.requiredField",
                          lang,
                        }),
                      }}
                    >
                      {/* TODO: generat this dynamically from Backend countries endpoint and those that have published country plan in both English and Arabic (remove it from translations and remove COUNTRY_NAMES_BY_CODE) */}
                      <Select.Option value="BH">
                        {formatMessage({ id: "bahrain", lang })}
                      </Select.Option>
                      <Select.Option value="EG">
                        {formatMessage({ id: "egypt", lang })}
                      </Select.Option>
                      <Select.Option value="JO">
                        {formatMessage({ id: "jordan", lang })}
                      </Select.Option>
                      <Select.Option value="KW">
                        {formatMessage({ id: "kuwait", lang })}
                      </Select.Option>
                      <Select.Option value="OM">
                        {formatMessage({ id: "oman", lang })}
                      </Select.Option>
                      <Select.Option value="QA">
                        {formatMessage({ id: "qatar", lang })}
                      </Select.Option>
                      <Select.Option value="SA">
                        {formatMessage({ id: "ksa", lang })}
                      </Select.Option>
                      <Select.Option value="AE">
                        {formatMessage({ id: "uae", lang })}
                      </Select.Option>
                    </Controller>
                  </FormField>
                )}
              </div>
              <FormField
                className={s.formField}
                error={errors.organizationName}
              >
                <Controller
                  as={Input}
                  control={control}
                  defaultValue={storeName || ""}
                  placeholder={`* ${formatMessage({
                    id: "registerPage.storeName",
                    lang,
                  })}`}
                  name="organizationName"
                  rules={{
                    required: formatMessage({
                      id: "registerPage.requiredField",
                      lang,
                    }),
                  }}
                />
              </FormField>
              <div className={s.twoColumnsFields}>
                <FormField className={s.formField} error={errors.firstName}>
                  <Controller
                    as={Input}
                    control={control}
                    defaultValue={firstName || ""}
                    name="firstName"
                    placeholder={`* ${formatMessage({
                      id: "registerPage.firstName",
                      lang,
                    })}`}
                    rules={{
                      required: formatMessage({
                        id: "registerPage.requiredField",
                        lang,
                      }),
                    }}
                  />
                </FormField>
                <FormField className={s.formField} error={errors.lastName}>
                  <Controller
                    as={Input}
                    control={control}
                    defaultValue={lastName || ""}
                    name="lastName"
                    placeholder={`* ${formatMessage({
                      id: "registerPage.lastName",
                      lang,
                    })}`}
                    rules={{
                      required: formatMessage({
                        id: "registerPage.requiredField",
                        lang,
                      }),
                    }}
                  />
                </FormField>
              </div>
              <FormField className={s.formField} error={errors.email}>
                <Controller
                  as={Input}
                  control={control}
                  defaultValue={email || ""}
                  name="email"
                  placeholder={`* ${formatMessage({ id: "email", lang })}`}
                  rules={{
                    required: formatMessage({
                      id: "registerPage.requiredField",
                      lang,
                    }),
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                      message: formatMessage({
                        id: "registerPage.invalidEntry",
                        lang,
                      }),
                    },
                  }}
                  type="email"
                />
              </FormField>
              <FormField className={s.formField} error={errors.mobile}>
                <Controller
                  as={PhoneInput}
                  control={control}
                  defaultValue={mobile || ""}
                  name="mobile"
                  onlyCountries={Object.keys(COUNTRY_NAMES_BY_CODE).map(item =>
                    item.toLowerCase()
                  )}
                  country={
                    (selectedCountry && selectedCountry.toLowerCase()) ||
                    (defaultCountry && defaultCountry.toLowerCase())
                  }
                  disableDropdown
                  rules={{
                    required: formatMessage({
                      id: "registerPage.requiredField",
                      lang,
                    }),
                  }}
                />
              </FormField>
              <FormField className={s.formField} error={errors.instagram}>
                <Controller
                  as={Input}
                  control={control}
                  defaultValue={instagram || ""}
                  name="instagram"
                  placeholder={`${formatMessage({
                    id: "registerPage.instagram",
                    lang,
                  })}`}
                />
              </FormField>
              {/* <div className={s.inputContainer}>
                {formatMessage({ id: "registerPage.commission", lang })}
                <input
                  name="Commission Checkbox"
                  type="checkbox"
                  checked={isCommissionBased}
                  onChange={() => setActiveCommission(!isCommissionBased)}
                />
              </div> */}
            </div>
            <small className={s.privacyPolicy}>
              {formatMessage({ id: "registerPage.privacyPolicy", lang })}
            </small>
            {submitButtons}
          </form>
        </div>
        {plan && (
          <div className={s.planContainer}>
            <Plan
              currencyUnit={translateField(
                plan.currency.titleAr,
                plan.currency.titleEn
              )}
              description={translateField(
                plan.descriptionAr,
                plan.descriptionEn
              )}
              discountText={translateField(
                plan.discountTextAr,
                plan.discountTextEn
              )}
              features={translateField(plan.featuresAr, plan.featuresEn)}
              flex={1}
              price={price}
              interval={plan.interval}
              key={plan.id}
              locale={lang}
              maxWidth={["unset"]}
              id={plan.id}
              title={translateField(plan.titleAr, plan.titleEn)}
              width={["auto"]}
              showSignupButton={false}
            />
          </div>
        )}
      </div>
    </div>
  )
}
export default SignupForm
