import React from "react"
import AlertSvg from "@images/alert-circle.svg"
import s from "./FormField.module.scss"

const FormField: React.FC<{ label?: string; error?; className? }> = ({
  children,
  error,
  label,
  ...props
}) => {
  return (
    <div {...props}>
      {label && <label>{label}</label>}
      {React.Children.map(children, child =>
        React.cloneElement(child, {
          hasError: !!error,
        })
      )}
      {error && (
        <div className={s.errorContainer}>
          <AlertSvg className={s.errorImg} />
          <p className={s.errorMsg}>{error.message}</p>
        </div>
      )}
    </div>
  )
}

export default FormField
